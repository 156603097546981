





































































































































































































































































































































































































































































  
  .divall{
    font-size:15px;
    color:white;
    padding-left:20px;
    line-height:50px;
    width:205px;
    text-align:left;
    cursor: pointer;
  }
  .allsel{
    font-size:15px;
    color:white;
    background-color: #ed4014;
    line-height:50px;
    width:205px;
    text-align:left;
    padding-left:20px;
    cursor: pointer;
  }   
  .el-menu-css{  
      height:50px;
      line-height:50px;
      display :flex ;
      width:1230px; 
      margin:0 auto;
      background-color: #5cadff ; 
  }
.el-dropdown-link {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    line-height:50px;
    text-align:center;
  } 
  .power-link {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    background-color: #ed4014;
    line-height:50px;
    width:155px;
    text-align:center;
  }
  .el-listitem {  
    cursor: pointer;
    font-size:15px;
    color:white !important;
    background: #5cadff;
    height:50px;
    line-height:50px;
    text-align:center;
  } 
  .ms-title {
    width: 1230px; 
    margin:0px auto;  
    background-color:#d7d7da;
    height:35px;
    line-height: 30px;
    margin-top:0px;
    font-size: 10px;
    color: #2b85e4;
  }
  .toolcss{
      font-size:10px;
      color:#2b85e4
  } 
